<template>
  <div>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <h3>{{ $t(config.label) }}</h3>
        </v-col>
    </v-row>     
    <v-row>               
        <v-col cols="3" sm="12" md="3"> 
            <v-text-field
                label="Évszám"
                v-model="inputData.year"
                type="number"
                min="0"
                max="9999"
                single-line
                solo
            ></v-text-field>           
        </v-col>
        
        <v-col cols="3" sm="12" md="3">
            <v-textarea
                v-if="inputData.type == 1 || inputData.type == 3"
                v-model="inputData.text"
                label="Évszámhoz tartozó szöveg"
                single-line
                solo
                auto-grow        
            ></v-textarea>
            
            <v-textarea
                v-if="inputData.type == 2 || inputData.type == 3"
                v-model="inputData.text2"
                label="Évszámhoz tartozó szöveg"
                single-line
                solo
                auto-grow        
            ></v-textarea>
        </v-col>
        
        <v-col cols="2" sm="12" md="2">    
            <v-radio-group
                inline
                label="Oldal"
                v-model="inputData.type"
            >
                <v-radio label="J.Pröepster" value="1"></v-radio>
                <v-radio label="REX Elektro" value="2"></v-radio>
                <v-radio label="Kiemelt" value="3"></v-radio>
            </v-radio-group>  
        </v-col>
        
        <v-col cols="2" sm="12" md="2"> 
            <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="handleOpenMediaSelectModal('image')"         
              >
                Kép
                <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
            
            <SelectMediaModalForm
                :modalData="mediaModalDataImage"
                :config="{
                  maxFiles: 1,
                }"
                @closeModalForm="handleCloseMediaModalForm"
                @saveModalForm="handleSaveImageModalFormItem"
            >
            </SelectMediaModalForm>
            
            <DeleteModalDialog
                :dialogDelete="dialogImageDelete"
                @closeDelete="dialogImageDelete = false"
                @deleteItemConfirm="handleImageDeleteItemConfirm"
            >
            </DeleteModalDialog>
            
            <CustomInputsModalDialog
                :customFieldInputData="customImageInputData"
                @handleCloseModalForm="handleCloseCustomInputModalForm"
                @handleSaveModalForm="handleImageSaveCustomInputModalForm"
            >
            </CustomInputsModalDialog>
         
            <v-card v-if="inputData.image">             
                <v-img
                  v-if="previewable_mime_types.includes(inputData.image.mime_type)"
                  :src="getFile(inputData.image)"
                  height="175"
                  style="cursor: pointer"
                  :title="inputData.image.name"
                ></v-img>
                <a v-else :href="getFile(inputData.image)" target="_blank">
                  <v-img
                    :src="getIcon(inputData.image)"
                    :title="inputData.image.name"
                    height="175"
                    contain
                  />
                </a>
                <v-card-actions style="height: 40px">
                    {{ inputData.image.name ? inputData.image.name.substring(0, 21) : "" }}
                    <v-spacer></v-spacer>
                    <v-icon
                        small
                        color="primary"
                        @click="dialogImageDelete = true"
                    >
                        mdi-delete
                    </v-icon>
                    <v-icon
                        small
                        color="primary"
                        @click="handleEditCustomFieldInputs(inputData.image, 'image')"
                    >
                        mdi-pencil
                    </v-icon>
                </v-card-actions>
            </v-card>         
        </v-col>
        <v-col cols="2" sm="12" md="2"> 
            <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="handleOpenMediaSelectModal('icon')"         
              >
                    Ikon
                    <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
	
                <SelectMediaModalForm
                        :modalData="mediaModalDataIcon"
                        :config="{
                          maxFiles: 1,
                        }"
                        @closeModalForm="handleCloseMediaModalForm"
                        @saveModalForm="handleSaveIconModalFormItem"
                >
                </SelectMediaModalForm>

                <DeleteModalDialog
                        :dialogDelete="dialogIconDelete"
                        @closeDelete="dialogIconDelete = false"
                        @deleteItemConfirm="handleIconDeleteItemConfirm"
                >
                </DeleteModalDialog>
            
                <CustomInputsModalDialog
                    :customFieldInputData="customIconInputData"
                    @handleCloseModalForm="handleCloseCustomInputModalForm"
                    @handleSaveModalForm="handleIconSaveCustomInputModalForm"
                >
                </CustomInputsModalDialog>

                <v-card v-if="inputData.icon">             
                        <v-img
                          v-if="previewable_mime_types.includes(inputData.icon.mime_type)"
                          :src="getFile(inputData.icon)"
                          height="175"
                          style="cursor: pointer"
                          :title="inputData.icon.name"
                        ></v-img>
                        <a v-else :href="getFile(inputData.icon)" target="_blank">
                          <v-img
                                :src="getIcon(inputData.icon)"
                                :title="inputData.icon.name"
                                height="175"
                                contain
                          />
                        </a>
                        <v-card-actions style="height: 40px">
                                {{ inputData.icon.name ? inputData.icon.name.substring(0, 21) : "" }}
                                <v-spacer></v-spacer>
                                <v-icon
                                        small
                                        color="primary"
                                        @click="dialogIconDelete = true"
                                >
                                        mdi-delete
                                </v-icon>
                                <v-icon
                                    small
                                    color="primary"
                                    @click="handleEditCustomFieldInputs(inputData.icon, 'icon')"
                                >
                                    mdi-pencil
                                </v-icon>
                        </v-card-actions>
                </v-card>         
        </v-col>
    </v-row> 

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn
          color="primary"
          @click="handleAddInputData"
          :disabled="
            inputData.type.length == 0 || inputData.year.length == 0 || inputData.text.length == 0
          "
        >
          {{ $t("FORMS.add") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row v-if="accordions.length > 0">
      <v-col cols="12">
        <v-expansion-panels focusable>
          <draggable v-model="draggableCards" style="width: 100%">
            <v-expansion-panel v-for="(item, i) in files" :key="item.name">
              <v-expansion-panel-header>
                <div v-html="item.year"></div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-if="item.text" v-html="item.text"></span>  
                <span v-if="item.text2" v-html="item.text2"></span>
                <v-spacer></v-spacer>
                <v-icon
                  :disabled="editedItemIndex != null"
                  color="primary"
                  style="
                    display: block;
                    font-size: 16px;
                    float: right;
                    margin-right: 5px;
                  "
                  small
                  @click="deleteItem(i)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  :disabled="editedItemIndex != null"
                  small
                  color="primary"
                  style="
                    cursor: pointer;
                    display: block;
                    font-size: 16px;
                    float: right;
                    margin-right: 5px;
                  "
                  @click="handleEditInputData(i, item)"
                >
                  mdi-pencil
                </v-icon>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
      <DeleteModalDialog
        :dialogDelete="dialogDelete"
        @closeDelete="handleCloseDelete"
        @deleteItemConfirm="handleDeleteItemConfirm"
      >
      </DeleteModalDialog>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import SelectMediaModalForm from "@/view/media_library/SelectMediaModalForm.vue";
import CustomInputsModalDialog from "@/view/components/CustomInputsModalDialog";
import { MIME_TYPE_ICONS } from "@/view/media_library/Browser.vue";

const INITIAL_INPUT_DATA = { 
    type: "", 
    year: "", 
    text: "",
    text2: "",
    image: "",
    icon: "",
};

export default {
  name: "TimelineCustomFieldComponent",
  components: { 
      draggable, 
      DeleteModalDialog,
      SelectMediaModalForm,
      CustomInputsModalDialog,
  },
  props: ["config", "accordions"],
  data() {
    return {
      inputData: Object.assign({}, INITIAL_INPUT_DATA),
      dialogDelete: false,
      dialogImageDelete: false,
      dialogIconDelete: false,
      itemToDeleteIndex: null,
      editedItemIndex: null,
      files: [],
           
      mediaModalDataImage: {
        dialog: false,
      },
      mediaModalDataIcon: {
        dialog: false,
      },
      
      previewable_mime_types: [
        "image/png",
        "image/jpeg",
        "image/x-ms-bmp",
        "image/webp",
        "image/gif",
        "image/svg+xml",
      ],
      
      customImageInputData: {
        dialog: false,
        file: null,
        inputFields: [],
      },
      customIconInputData: {
        dialog: false,
        file: null,
        inputFields: [],
      }
    };
  },

  watch: {
    accordions(newValue) {
      this.files = newValue;
    },
  },

  computed: {
    draggableCards: {
      get() {
        // return this.accordions;
        return this.files;
      },
      set(val) {
        this.files = val;
        this.accordions = val;
        this.handleChangeValue();
      },
    },
  },

  methods: {
    handleAddInputData() {
      if (!this.accordions) {
        this.accordions = [];
      }

      if (this.editedItemIndex == null) {
        this.accordions.push(Object.assign({}, this.inputData));
      } else {
        this.accordions[this.editedItemIndex] = Object.assign(
          {},
          this.inputData
        );
      }

      this.files = this.accordions;

      this.inputData = Object.assign({}, INITIAL_INPUT_DATA);

      this.editedItemIndex = null;
      this.handleChangeValue();
    },

    deleteItem(i) {
      this.itemToDeleteIndex = i;
      this.dialogDelete = true;
    },

    handleCloseDelete() {
      this.itemToDeleteIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItemConfirm() {
      this.accordions.splice(this.itemToDeleteIndex, 1);

      this.files = this.accordions;
      this.handleChangeValue();
      this.handleCloseDelete();
    },

    handleEditInputData(index, item) {
      if (this.editedItemIndex == null) {
        this.editedItemIndex = index;
        this.inputData = Object.assign({}, item);
      }
    },

    handleChangeValue() {
      this.$emit("handleChangeValue", this.accordions);
    },
    
    //Media selector
    handleOpenMediaSelectModal(type) { 
        if(type == 'image') {
            this.mediaModalDataImage.dialog = true;
        }
        else if(type == 'icon') {
            this.mediaModalDataIcon.dialog = true;
        }
    }, 
    
    getFile(file) {
      return (
        process.env.VUE_APP_BACKEND_URL.replace(/\/+$/, "") + "/" + file.path
      );
    },
    getIcon(file) {
      if (
        file.mime_type in MIME_TYPE_ICONS &&
        MIME_TYPE_ICONS[file.mime_type] != ""
      ) {
        return (
          "/media/media_library/mime_types/" + MIME_TYPE_ICONS[file.mime_type]
        );
      } else {
        return "/media/media_library/file-outline.svg";
      }
    },
    
    handleSaveImageModalFormItem(selectedFiles) {      
        this.inputData.image = Object.assign({}, selectedFiles[0]);             
        this.handleCloseMediaModalForm();
    },
    
    handleSaveIconModalFormItem(selectedFiles) {      
        this.inputData.icon = Object.assign({}, selectedFiles[0]);             
        this.handleCloseMediaModalForm();
    },
    
    handleImageDeleteItemConfirm() {
        this.inputData.image = null,
        this.dialogImageDelete = false;
    },
    handleIconDeleteItemConfirm() {
        this.inputData.icon = null,
        this.dialogIconDelete = false;
    },   
    handleCloseMediaModalForm() {
      this.mediaModalDataImage.dialog = false;
      this.mediaModalDataIcon.dialog = false;
    },
    
    handleCloseCustomInputModalForm() {
      this.customImageInputData.dialog = false;
      this.customIconInputData.dialog = false;
    },
    
    handleImageSaveCustomInputModalForm(val) {
      this.inputData.image = val;  
      this.handleCloseCustomInputModalForm();
    },
    handleIconSaveCustomInputModalForm(val) {
      this.inputData.icon = val;  
      this.handleCloseCustomInputModalForm();
    },
    
    handleEditCustomFieldInputs(file, type) { 
        let inputData = {
            dialog: true,
            inputFields: [
                {
                  name: 'alt_text',
                  label: 'FORM_INPUT_NAMES.alt_text',
                  type: 'text',
                },
                {
                  name: 'title',
                  label: 'FORM_INPUT_NAMES.title',
                  type: 'text',
                }
            ],
            file: file,
        };
        if(type == 'image') {
            this.customImageInputData = null;
            this.customImageInputData = inputData;
        }
        if(type == 'icon') {
            this.customIconInputData = null;
            this.customIconInputData = inputData;
        }
    },
  },
};
</script>

